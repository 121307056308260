<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                Unfollow
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import BookingService from '@/services/BookingService'

export default {
  name: 'BookingUnfollow',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      }
    }
  },
  components: {
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    let id = this.$route.params.id
    this.onUnfollow(id)
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
    },
    onUnfollow (id) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja deixar de seguir este booking?',
        'warning', 'Sim', 'Não', (e) => this.unsubscribe(id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Seguidor excluído com sucesso')
        } else {
          this.$alertSwal.toast('Falha ao deixar de seguir!', 'error')
        }

        this.$router.push({ name: 'BookingIndex' })
      })
    },
    async unsubscribe (id) {
      let _this = this
      _this.isLoading = true
      let formData = new FormData()
      formData.append('booking', id)
      await BookingService.unsubscribe(formData).then(res => {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
</style>
